import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';
import { AuthGuard } from './auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        canActivate: [AuthGuard],
        data : {module_id : 'common'},
        path: 'dashboard',
        loadChildren: './page/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'region',
        loadChildren: './page/region/region.module#RegionModule'
      },
      {
        path: 'company-management',
        loadChildren: './page/company-management/company-management.module#CompanyManagementModule'
      },
      {
        path: 'policy-management',
        loadChildren: './page/policy-management/policy-management.module#PolicyManagementModule'
      },
      // {
      //   path: 'tax-management',
      //   loadChildren: './page/tax-management/tax-management.module#TaxManagementModule'
      // },
    //   {
    //     path: 'tax-management',
    //     loadChildren: './page/tax-management/tax-management.module#TaxManagementModule'
    //   },
      {
        path: 'user-management',
        loadChildren: './page/user-management/user-management.module#UserManagementModule'
      },
      {
        path: 'product-management',
        loadChildren: './page/product-management/product-management.module#ProductManagementModule'
      },
      {
        path: 'production-master',
        loadChildren: './page/production-master/production.module#ProductionModule'
      },
      {
        path: 'purchase',
        loadChildren: './page/purchase/purchase.module#PurchaseModule'
      },
      {
        path: 'address-book',
        loadChildren: './page/address-book/address-book.module#AddressBookModule'
      },
      {
        path: 'rm-management',
        loadChildren: './page/rm-management/rm-management.module#RmManagementModule'
      },
      {
        path: 'dashboard',
        loadChildren: './theme/dashboard/dashboard.module#DashboardModule'
        
      },
      {
        path: 'reports',
        loadChildren: './page/reports/reports.module#ReportsModule'
      },
      {
        path: 'stores',
        loadChildren: './page/stores/stores.module#StoresModule'
      },
      {
        path: 'finance',
        loadChildren: './page/finance/finance.module#FinanceModule'
      },
      {
        path: 'dispatch',
        loadChildren: './page/dispatch/dispatch.module#DispatchModule'
      },
      {
        path: 'sales',
        loadChildren: './page/sales/sales.module#SalesModule'
      },
      {
        path: 'production',
        loadChildren: './page/production/production.module#ProructionModule'
      },
      // {
      //   path: 'navigation',
      //   loadChildren: './theme/navigation/navigation.module#NavigationModule'
      // },
      // {
      //   path: 'widget',
      //   loadChildren: './theme/widget/widget.module#WidgetModule'
      // },
      // {
      //   path: 'basic',
      //   loadChildren: './theme/ui-elements/basic/basic.module#BasicModule'
      // },
      // {
      //   path: 'advance',
      //   loadChildren: './theme/ui-elements/advance/advance.module#AdvanceModule'
      // },
      // {
      //   path: 'animations',
      //   loadChildren: './theme/ui-elements/animation/animation.module#AnimationModule'
      // },
      // {
      //   path: 'forms',
      //   loadChildren: './theme/forms/forms.module#FormsModule'
      // },
      // {
      //   path: 'bootstrap-table',
      //   loadChildren: './theme/table/bootstrap-table/bootstrap-table.module#BootstrapTableModule'
      // },
      // {
      //   path: 'data-table',
      //   loadChildren: './theme/table/data-table/data-table.module#DataTableModule'
      // },
      // {
      //   path: 'user',
      //   loadChildren: './theme/user/user.module#UserModule'
      // },
      // {
      //   path: 'email',
      //   loadChildren: './theme/email/email.module#EmailModule'
      // },
      // {
      //   path: 'crm-contact',
      //   loadChildren: './theme/crm-contact/crm-contact.module#CrmContactModule'
      // },
      // {
      //   path: 'task',
      //   loadChildren: './theme/task/task.module#TaskModule'
      // },
      // {
      //   path: 'editor',
      //   loadChildren: './theme/extension/editor/editor.module#EditorModule'
      // },
      // {
      //   path: 'invoice',
      //   loadChildren: './theme/extension/invoice/invoice.module#InvoiceModule'
      // },
      // {
      //   path: 'file-upload-ui',
      //   loadChildren: './theme/extension/file-upload-ui/file-upload-ui.module#FileUploadUiModule'
      // },
      // {
      //   path: 'calendar',
      //   loadChildren: './theme/extension/event-calendar/event-calendar.module#EventCalendarModule'
      // },
      // {
      //   path: 'charts',
      //   loadChildren: './theme/chart/chart.module#ChartModule'
      // },
      // {
      //   path: 'map',
      //   loadChildren: './theme/map/map.module#MapModule'
      // },
      // {
      //   path: 'simple-page',
      //   loadChildren: './theme/simple-page/simple-page.module#SimplePageModule'
      // }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: './theme/auth/auth.module#AuthModule'
      },
      {
        path: 'maintenance/error',
        loadChildren: './theme/maintenance/error/error.module#ErrorModule'
      },
      {
        path: 'maintenance/coming-soon',
        loadChildren: './theme/maintenance/coming-soon/coming-soon.module#ComingSoonModule'
      },
      {
        path: 'email/email-template',
        loadChildren: './theme/email/email-template/email-template.module#EmailTemplateModule'
      },
      {
        path: 'maintenance/offline-ui',
        loadChildren: './theme/maintenance/offline-ui/offline-ui.module#OfflineUiModule'
      },
      //custome login
      {
        path: '',
        loadChildren: './page/auth/auth.module#AuthModule'
      },
    ]

  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
