import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// import { SrvRecord } from 'dns';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'indexof'})
export class Indexof implements PipeTransform {
  transform(value: string, exponent: string): boolean {

  	console.log(value);

    if(value.indexOf(exponent) > -1 ){
      console.log("true");
      return true;
        
    }else{
    	
      console.log("false");
      return false;
    }
  }
}

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'removeHyphen'})
export class removeHyphen implements PipeTransform {
  transform(value: string): string {
    if(value){
      return value.replace(/-/g,"");
    }else{
      return value;
    }
  }
}

// \n converts in <br>
@Pipe({name: 'nl2br'})
export class nl2br implements PipeTransform {
transform(value: string): string {
  if(value && typeof value == 'string'){
      return value.replace(/\n/g, '<br/>');
  }
  else{
    return value;
  }
   }
}


@Pipe({name: 'trim'})
export class trim implements PipeTransform {
transform(value: string): string {
  if(value && typeof value == 'string'){
      return value.trim();
  }
  else{
    return value;
  }
   }
}

@Pipe({ name: 'safeHtml'})
export class safeHtml implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

