import {Injectable,Inject} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import {Observable} from "rxjs";
import { DOCUMENT } from "@angular/common";
import {Router} from "@angular/router";
import { CommonService } from '../common-service.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Inject(DOCUMENT) private document: any,private router: Router,private commonService :CommonService){
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('intercept');
  
    req = req.clone({
      setHeaders: {
        Authorization: `${localStorage.getItem(this.commonService.prefix + 'authentic_token')}`
      }
    });


    return next.handle(req).do(
      (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
              return event;
          }
      },
      (err) => {
        
          if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                localStorage.removeItem(this.commonService.prefix+'userData')
                this.router.navigate(['/login'], {
                  queryParams: {
                    return: this.router.url
                  }
                });
                return false;
                //   this.document.location.href =
                //   `${this.document.location.protocol}//${this.document.location.hostname}:${this.document.location.port}/user/login`;
              }
          }
      });


   // return next.handle(req);
  }
}