import { Injectable } from '@angular/core';
import { CanActivate, Router,CanActivateChild ,RouterStateSnapshot,ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import { CommonService } from './common-service.service';

@Injectable()
export class AuthGuard implements  CanActivate, CanActivateChild {
  
  constructor(private commonService: CommonService,private router: Router ) {}
  
  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean {
    // console.log('i am checking to see if you are logged in');
    // console.log(state)
    let routeData = route.data || {};
    if(routeData['module_id'])
    { 
      return this.commonService.sendRequestToNode({method :'get',path:'get-login-detail',url:'http://localhost:8042'})
      .map(data => {
        if(data.status == true)
            {
              let loginData;
              loginData = JSON.stringify(data.loginData);
              localStorage.setItem(this.commonService.prefix+'userData', loginData);
              let permission = data.loginData.permission ? JSON.parse(data.loginData.permission) : {}
              if((data.loginData.role_id == 1) || routeData['module_id'] == 'common'){
                return true;
              }
              else if(permission[routeData.parent_module_id] && permission[routeData.parent_module_id][routeData.module_id] && permission[routeData.parent_module_id][routeData.module_id]['view']){ // this for all accessible
                return true;
              }
              else{
                this.router.navigate(['dashboard']);

                // this.router.navigate(['authentication/403']);
                return false;
                
                // let permission = data.loginData.permission ? JSON.parse(data.loginData.permission) : ''; 
                // if(permission[module_name])
                // {
                //   return true;
                // }
              }
            }
            else{
                // return false;
              
                localStorage.removeItem(this.commonService.prefix+'userData');
                this.router.navigate(['/login'] 
                // {
                //   queryParams: {
                //     return: state.url
                //   }
                // }
                );
          
            }
      })
  
    }
    else
    {
      return true;
    }
  }

  canActivateChild() {
    console.log('checking child route access');
    return true;
  }

}