import { Injectable } from '@angular/core';
import { CanActivate,Router, CanActivateChild ,ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import { CommonService } from './common-service.service';

@Injectable()
export class LoginGuard implements  CanActivate, CanActivateChild {
  
  constructor(private commonService: CommonService,private router: Router ) {}
  
  canActivate(route: ActivatedRouteSnapshot) {
    // console.log('i am checking to see if you are logged in');
    // console.log(route.data);
    if(!this.commonService.getUserDataFromLocalStorage())
    {
      return true;
    }
    else{
      this.router.navigate(['/home']); // 
    }
  }

  canActivateChild() {
    console.log('checking child route access');
    return true;
  }

}