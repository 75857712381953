import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GridViewComponent } from './grid-view.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { nl2br,trim } from '../../common.pipe';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    imports: [ 
        RouterModule,
        CommonModule,
        FormsModule,NgbModule.forRoot(),
        NgSelectModule   
    ],
    declarations: [ 
        GridViewComponent,
        nl2br,
        trim,
    ],
    exports: [ 
        GridViewComponent
    ]
})

export class GridViewModule {}