import {Component, OnInit, Input, ViewEncapsulation} from '@angular/core';
import { container } from '@angular/core/src/render3';

@Component({
  selector: 'app-modal-basic',
  templateUrl: './modal-basic.component.html',
  styleUrls: ['./modal-basic.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalBasicComponent implements OnInit {
  @Input() dialogClass: string;
  @Input() hideHeader = false;
  @Input() hideFooter = false;
  @Input() containerClick = true;
  public visible = false;
  public visibleAnimate = false;

  constructor() {}

  ngOnInit() {

  }

  public show(): void {
    this.visible = true;
    setTimeout(() => {
      console.log(document.querySelectorAll('app-modal-basic form')[0].getElementsByTagName('input')[0].focus());
      if(document.querySelectorAll('app-modal-basic form')[0].getElementsByTagName('input')[0]){
        document.querySelectorAll('app-modal-basic form')[0].getElementsByTagName('input')[0].focus()
      }
      else if(document.forms[0].getElementsByTagName('select')[0]){
        document.forms[0].getElementsByTagName('select')[0].focus();
      }
      this.visibleAnimate = true
    }, 100);
  }

  public hide(): void {
    this.visibleAnimate = false;
    setTimeout(() => this.visible = false, 300);
  }
  public onContainerClicked(event: MouseEvent): void {
    if(this.containerClick){
      if ((<HTMLElement>event.target).classList.contains('modal')) {
        this.hide();
      }
    }
    
  }

}
