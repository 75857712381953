import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { AdminComponent } from "./layout/admin/admin.component";
import { AuthComponent } from "./layout/auth/auth.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { MenuItems } from "./shared/menu-items/menu-items";
import { BreadcrumbsComponent } from "./layout/admin/breadcrumbs/breadcrumbs.component";
import { AuthGuard } from "./auth-guard.service";
import { LoginGuard } from "./login-guard.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./interceptors/auth.service";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ButtonRendererComponent } from './button-renderer.component';
import {NumericEditorComponent} from "./numeric-editor.component";
import { AgGridModule } from 'ag-grid-angular';
@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    BreadcrumbsComponent,
    ButtonRendererComponent,
    NumericEditorComponent
    
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    AgGridModule.withComponents(
      [
        ButtonRendererComponent,
        NumericEditorComponent
        
      ]),
      FormsModule,
  ],
  providers: [
    MenuItems,
    AuthGuard,
    LoginGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ButtonRendererComponent,
    NumericEditorComponent
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
