import {Injectable} from '@angular/core';
import { CommonService } from 'src/app/common-service.service';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  ID : number;
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  ID : number;
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Home',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard1',
        type: 'link',
        icon: 'icon-home'
      },
    ],
  },

  {
    label: '',
    main: [
      {
        state: 'region',
        short_label: 'R',
        name: 'Region',
        type: 'sub',
        icon: 'icon-home',
        children: [
          {
            state: 'country',
            name: 'Country'
          },
          {
            state: 'state',
            name: 'State'
          },
          {
            state: 'city',
            name: 'City'
          },
          {
            state: 'zone',
            name: 'Zone'
          },
        ]
      },
      {
        state: 'company-management',
        short_label: 'CM',
        name: 'Company Management',
        type: 'sub',
        icon: 'icon-home',
        children: [
          {
            state: 'company-master',
            name: 'Company Master'
          },
          {
            state: 'unit-master',
            name: 'Unit Master'
          },
          {
            state: 'department',
            name: 'Department'
          },
          {
            state: 'sub-departments',
            name: 'Sub Departments'
          },
          {
            state: 'designations',
            name: 'Designations'
          },
        ]
      },
      {
        state: 'user-management',
        short_label: 'UM',
        name: 'User Management',
        type: 'sub',
        icon: 'icon-home',
        children: [
          {
            state: 'role-master',
            name: 'Role Master'
          },
          {
            state: 'role-detail',
            name: 'Permission'
          },
          {
            state: 'user-master',
            name: 'User Master'
          },
          {
            state: 'employee-master',
            name: 'Employee Master'
          },
        ]
      },
      {
        state: 'production-masters',
        short_label: 'PM',
        name: 'Production Masters',
        type: 'sub',
        icon: 'icon-home',
        children: [
          {
            state: 'machines-master',
            name: 'Machines Master'
          },
          {
            state: 'process-master',
            name: 'Process Master'
          },
          {
            state: 'process-machine',
            name: 'Process & Machine'
          },
        ]
      },
      {
        state: 'product-management',
        short_label: 'PM',
        name: 'Product Management',
        type: 'sub',
        icon: 'icon-home',
        children: [
          {
            state: 'main-group',
            name: 'Main Group'
          },
          {
            state: 'group',
            name: 'Group'
          },
          {
            state: 'sub-group',
            name: 'Sub Group'
          },
          {
            state: 'item-master',
            name: 'Item Master'
          },
          {
            state: 'item-price-change',
            name: 'Item Price Change'
          },
          {
            state: 'uom',
            name: 'Uom'
          },
          {
            state: 'bill-of-material',
            name: 'Bill Of Material'
          }
         
        ]
      },
      {
        state: 'policy-management',
        short_label: 'PM',
        name: 'Policy Management',
        type: 'sub',
        icon: 'icon-home',
        children: [
          {
            state: 'policy-category',
            name: 'T&C Group'
          },
          {
            state: 'purchase-policy',
            name: 'Purchase T&C'
          },
          {
            state: 'sales-policy',
            name: 'Sales T&C'
          },
          {
            state: 'currency-master',
            name: 'Currency'
          },
          {
            state: 'currency-master',
            name: 'Currency2'
          },
        ]
      },
      {
        state: 'tax-management',
        short_label: 'TM',
        name: 'Tax Management',
        type: 'sub',
        icon: 'icon-home',
        children: [
          {
            state: 'taxation',
            name: 'Taxation'
          },
          {
            state: 'taxation-template',
            name: 'Taxation Template'
          },
        ]
      },
      {
        state: 'address-book',
        short_label: 'AB',
        name: 'Address Book',
        type: 'sub',
        icon: 'icon-home',
        children: [
          {
            state: 'industry-type',
            name: 'Industry Type'
          },
          {
            state: 'customer-master',
            name: 'Customer Master'
          },
          {
            state: 'supplier-master',
            name: 'Supplier Master'
          },
          {
            state: 'vendor-master',
            name: 'Vendor Master'
          },
          {
            state: 'vendor-items',
            name: 'Vendor Items'
          },
        ]
      },
      {
        state: 'configurations',
        short_label: 'C',
        name: 'Configurations',
        type: 'link',
        icon: 'icon-home'
      },
    ],
  },

  {
    label: 'Sales',
    main: [
      {
        state: 'leads',
        short_label: 'L',
        name: 'Leads',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'enquiry',
        short_label: 'E',
        name: 'Enquiry',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'quotations',
        short_label: 'Q',
        name: 'Quotations',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'follow-ups',
        short_label: 'FU',
        name: 'Follow Ups',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'sales-order',
        short_label: 'SO',
        name: 'Sales Order',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'sales-order-approval',
        short_label: 'SOA',
        name: 'Sales Order Approval',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'dispatch-instruction',
        short_label: 'DI',
        name: 'Dispatch Instruction',
        type: 'link',
        icon: 'icon-home'
      },
    ],
  },

  {
    label: 'Planning',
    main: [
      {
        state: 'production-order',
        short_label: 'PO',
        name: 'Production Order',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'material',
        short_label: 'M',
        name: 'Material',
        type: 'link',
        icon: 'icon-home'
      },
    ],
  },

  {
    label: 'Purchase',
    main: [
      {
        state: 'purchase-requisition',
        short_label: 'PR',
        name: 'Purchase Requisition',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'purchase-inquiry',
        short_label: 'PI',
        name: 'Purchase Inquiry',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'vendor-quotation',
        short_label: 'VQ',
        name: 'Vendor Quotation',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'quotation-analysis',
        short_label: 'QA',
        name: 'Quotation Analysis',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'purchase-order',
        short_label: 'PO',
        name: 'Purchase Order',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'po-approval',
        short_label: 'PA',
        name: 'PO Approval',
        type: 'link',
        icon: 'icon-home'
      },
    ],
  },


  {
    label: 'Stores',
    main: [
      {
        state: 'store-requisition',
        short_label: 'SR',
        name: 'Store Requisition',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'goods-inward-note',
        short_label: 'GIN',
        name: 'Goods Inward Note1',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'goods-receipt-note',
        short_label: 'GRN',
        name: 'Goods Receipt Note1',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'stock-adjustment',
        short_label: 'SA',
        name: 'Stock Adjustment',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'supplier-invoice',
        short_label: 'SI',
        name: 'Purchase Invoice',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'quality-check',
        short_label: 'QC',
        name: 'Quality Check',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'store-purchase',
        short_label: 'SPR',
        name: 'Store to Purchase',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'material-issue',
        short_label: 'MI',
        name: 'Material Issue',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'production-return',
        short_label: 'PR',
        name: 'Production Return',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'store-loss',
        short_label: 'SL',
        name: 'Store Loss',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'gate-pass',
        short_label: 'GP',
        name: 'Gate Pass',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'sales-return',
        short_label: 'SR',
        name: 'Sales Return',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'supplier-invoice',
        short_label: 'SI',
        name: 'Purchase Invoice',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'inter-store-transfer',
        short_label: 'IST',
        name: 'Inter Store Transfer',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'stock-transfer',
        short_label: 'ST',
        name: 'Stock (Job) Transfer',
        type: 'link',
        icon: 'icon-home'
      },
    ],
  },

  {
    label: 'Production',
    main: [
      {
        state: 'store-requisition',
        short_label: 'SR',
        name: 'Store Requisition',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'daily-production',
        short_label: 'DP',
        name: 'Daily Production',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'quality-assurance',
        short_label: 'QA',
        name: 'Quality Assurance',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'Finished Good Transfer',
        short_label: 'FGT',
        name: 'Finished Good Transfer',
        type: 'link',
        icon: 'icon-home'
      },
    ],
  },

  {
    label: 'Dispatch',
    main: [
      {
        state: 'invoice',
        short_label: 'I',
        name: 'Invoice',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'payment-receivables',
        short_label: 'PR',
        name: 'Payment Receivables',
        type: 'link',
        icon: 'icon-home'
      },
    ],
  },

  {
    label: 'Reports',
    main: [
      {
        state: 'sales',
        short_label: 'S',
        name: 'Sales',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'purchase',
        short_label: 'P',
        name: 'Purchase',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'stores',
        short_label: 'S',
        name: 'Stores',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'planning',
        short_label: 'P',
        name: 'Planning',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'production',
        short_label: 'P',
        name: 'Production',
        type: 'link',
        icon: 'icon-home'
      },
      {
        state: 'dispatch',
        short_label: 'D',
        name: 'Dispatch',
        type: 'link',
        icon: 'icon-home'
      },
    ],
  },







  

  {
    label: 'RAW MATERIALS',
    main: [
      {
        state: 'rm-management',
        short_label: 'D',
        name: 'RM MANAGEMENT',
        type: 'sub',
        icon: 'icon-home',
        children: [
          {
            state: 'rm-categories',
            name: 'RM Categories'
          },
          {
            state: 'raw-material-master',
            name: 'Raw Material Master'
          },
          {
            state: 'opening-stock',
            name: 'Opening Stock'
          },
          {
            state: 'crm-dashboard',
            name: 'CRM'
          },
          {
            state: 'analytics',
            name: 'Analytics',
            badge: [
              {
                type: 'info',
                value: 'NEW'
              }
            ]
          },
          {
            state: 'project',
            name: 'Project'
          }
        ]
      },
      {
        state: 'navigation',
        short_label: 'N',
        name: 'Navigation',
        type: 'link',
        icon: 'icon-layout-cta-right'
      },
      {
        state: 'widget',
        short_label: 'W',
        name: 'Widget',
        type: 'sub',
        icon: 'icon-view-grid',
        badge: [
          {
            type: 'danger',
            value: '100+'
          }
        ],
        children: [
          {
            state: 'statistic',
            name: 'Statistic'
          },
          {
            state: 'data',
            name: 'Data'
          },
          {
            state: 'chart',
            name: 'Chart'
          },
          {
            state: 'advanced',
            name: 'Advance'
          }
        ]
      }
    ],
  },
  {
    label: 'Navigation',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'sub',
        icon: 'icon-home',
        children: [
          {
            state: 'default',
            name: 'Default'
          },
          {
            state: 'ecommerce',
            name: 'Ecommerce'
          },
          {
            state: 'crm-dashboard',
            name: 'CRM'
          },
          {
            state: 'analytics',
            name: 'Analytics',
            badge: [
              {
                type: 'info',
                value: 'NEW'
              }
            ]
          },
          {
            state: 'project',
            name: 'Project'
          }
        ]
      },
      {
        state: 'navigation',
        short_label: 'N',
        name: 'Navigation',
        type: 'link',
        icon: 'icon-layout-cta-right'
      },
      {
        state: 'widget',
        short_label: 'W',
        name: 'Widget',
        type: 'sub',
        icon: 'icon-view-grid',
        badge: [
          {
            type: 'danger',
            value: '100+'
          }
        ],
        children: [
          {
            state: 'statistic',
            name: 'Statistic'
          },
          {
            state: 'data',
            name: 'Data'
          },
          {
            state: 'chart',
            name: 'Chart'
          },
          {
            state: 'advanced',
            name: 'Advance'
          }
        ]
      }
    ],
  },
  {
    label: 'UI Element',
    main: [
      {
        state: 'basic',
        short_label: 'B',
        name: 'Basic',
        type: 'sub',
        icon: 'icon-layout-grid2-alt',
        children: [
          {
            state: 'alert',
            name: 'Alert'
          },
          {
            state: 'breadcrumb',
            name: 'Breadcrumbs'
          },
          {
            state: 'button',
            name: 'Button'
          },
          {
            state: 'box-shadow',
            name: 'Box-Shadow'
          },
          {
            state: 'accordion',
            name: 'Accordion'
          },
          {
            state: 'generic-class',
            name: 'Generic Class'
          },
          {
            state: 'tabs',
            name: 'Tabs'
          },
          {
            state: 'color',
            name: 'Color'
          },
          {
            state: 'label-badge',
            name: 'Label Badge'
          },
          {
            state: 'progressbar',
            name: 'Progressbar'
          },
          {
            state: 'pre-loader',
            name: 'Pre-Loader'
          },
          {
            state: 'list',
            name: 'List'
          },
          {
            state: 'tooltip',
            name: 'Tooltip & Popover'
          },
          {
            state: 'typography',
            name: 'Typography'
          },
          {
            state: 'other',
            name: 'Other'
          },
        ]
      },
      {
        state: 'advance',
        short_label: 'A',
        name: 'Advance',
        type: 'sub',
        icon: 'icon-crown',
        children: [
          {
            state: 'task-board',
            name: 'Task Board'
          },
          {
            state: 'grid-stack',
            name: 'Grid Stack'
          },
          {
            state: 'light-box',
            name: 'Light Box'
          },
          {
            state: 'modal',
            name: 'Modal'
          },
          {
            state: 'notifications',
            name: 'Notifications'
          },
          /*{
            state: 'notify',
            name: 'PNOTIFY',
            badge: [
              {
                type: 'info',
                value: 'New'
              }
            ]
          },*/
          {
            state: 'rating',
            name: 'Rating'
          },
          {
            state: 'range-slider',
            name: 'Range Slider'
          },
          {
            state: 'slider',
            name: 'Slider'
          },
          /*{
            state: 'tour',
            name: 'Tour'
          },*/
          {
            state: 'tree',
            name: 'Tree View'
          }
        ]
      },
      {
        state: 'animations',
        short_label: 'A',
        name: 'Animations',
        type: 'link',
        icon: 'icon-reload rotate-refresh'
      }
    ]
  },
  {
    label: 'Forms',
    main: [
      {
        state: 'forms',
        short_label: 'F',
        name: 'Form',
        type: 'sub',
        icon: 'icon-layers',
        children: [
          {
            state: 'basic',
            name: 'Components'
          }, {
            state: 'add-on',
            name: 'Add-On'
          }, {
            state: 'advance',
            name: 'Advance'
          }, {
            state: 'validation',
            name: 'Validation'
          }
        ]
      },
      {
        state: 'picker',
        short_label: 'P',
        main_state: 'forms',
        name: 'Form Picker',
        type: 'link',
        icon: 'icon-pencil-alt'
      },
      {
        state: 'mask',
        short_label: 'M',
        main_state: 'forms',
        name: 'Form Mask',
        type: 'link',
        icon: 'feather icon-package',
        badge: [
          {
            type: 'warning',
            value: 'New'
          }
        ]
      },
      {
        state: 'select',
        short_label: 'S',
        main_state: 'forms',
        name: 'Form Select',
        type: 'link',
        icon: 'icon-shortcode'
      },
      {
        state: 'form-wizards',
        short_label: 'FW',
        main_state: 'forms',
        name: 'Form Wizard',
        type: 'link',
        icon: 'feather icon-user'
      },
      {
        state: 'ngx',
        short_label: 'NFW',
        main_state: 'forms',
        name: 'NGX Form Wizard',
        type: 'link',
        icon: 'feather icon-list'
      }
    ]
  },
  {
    label: 'Tables',
    main: [
      {
        state: 'bootstrap-table',
        short_label: 'B',
        name: 'Bootstrap Table',
        type: 'sub',
        icon: 'icon-receipt',
        children: [
          {
            state: 'basic',
            name: 'Basic Table'
          }, {
            state: 'sizing',
            name: 'Sizing Table'
          }, {
            state: 'border',
            name: 'Border Table'
          }, {
            state: 'styling',
            name: 'Styling Table'
          }
        ]
      },
      {
        state: 'data-table',
        short_label: 'D',
        name: 'Data Table',
        type: 'sub',
        icon: 'icon-widgetized',
        children: [
          {
            state: 'basic',
            name: 'Basic Table'
          }, {
            state: 'editable',
            name: 'Editable'
          }, {
            state: 'row-details',
            name: 'Row Details Table'
          }, {
            state: 'paging',
            name: 'Paging Table'
          }, {
            state: 'selection',
            name: 'Selection Table'
          }, {
            state: 'other',
            name: 'Other'
          }
        ]
      }
    ]
  },
  {
    label: 'Chart And Map',
    main: [
      {
        state: 'charts',
        short_label: 'C',
        name: 'Charts',
        type: 'sub',
        icon: 'icon-bar-chart-alt',
        children: [
          {
            state: 'google',
            name: 'Google'
          }, {
            state: 'chart-js',
            name: 'ChartJS'
          }, {
            state: 'radial',
            name: 'Radial'
          }, {
            state: 'c3-js',
            name: 'C3 JS'
          }
        ]
      },
      {
        state: 'map',
        short_label: 'M',
        name: 'Maps',
        type: 'sub',
        icon: 'icon-map-alt',
        children: [
          {
            state: 'google',
            name: 'Google'
          }
        ]
      },
      /*{
        state: 'landing',
        short_label: 'L',
        external: 'http://html.phoenixcoded.net/mega-able-5/default/landingpage',
        name: 'Landing Page',
        type: 'external',
        icon: 'icon-mobile',
        target: true
      }*/
    ]
  },
  {
    label: 'Pages',
    main: [
      {
        state: 'auth',
        short_label: 'A',
        name: 'Authentication',
        type: 'sub',
        icon: 'icon-id-badge',
        children: [
          {
            state: 'login',
            type: 'sub',
            name: 'Login Pages',
            children: [
              {
                state: 'simple',
                name: 'Simple',
                target: true
              }, {
                state: 'header-footer',
                name: 'Header & Footer',
                target: true
              }, {
                state: 'social',
                name: 'Social Icon',
                target: true
              }, {
                state: 'social-header-footer',
                name: 'Social Header & Footer',
                target: true
              }
            ]
          }, {
            state: 'registration',
            type: 'sub',
            name: 'Registration Pages',
            children: [
              {
                state: 'simple',
                name: 'Simple',
                target: true
              }, {
                state: 'header-footer',
                name: 'Header & Footer',
                target: true
              }, {
                state: 'social',
                name: 'Social',
                target: true
              }, {
                state: 'social-header-footer',
                name: 'Social Header & Footer',
                target: true
              }
            ]
          },
          {
            state: 'forgot',
            name: 'Forgot Password',
            target: true
          },
          {
            state: 'lock-screen',
            name: 'Lock Screen',
            target: true
          },
        ]
      },
      {
        state: 'maintenance',
        short_label: 'A',
        name: 'Maintenance',
        type: 'sub',
        icon: 'icon-settings',
        children: [
          {
            state: 'error',
            short_label: 'A',
            name: 'Error Pages',
            type: 'sub',
            icon: 'feather icon-alert-triangle',
            children: [
              {
                state: '400',
                name: 'Error 400',
                target: true
              },
              {
                state: '403',
                name: 'Error 403',
                target: true
              },
              {
                state: '404',
                name: 'Error 404',
                target: true
              },
              {
                state: '500',
                name: 'Error 500',
                target: true
              },
              {
                state: '503',
                name: 'Error 503',
                target: true
              }
            ]
          },
          {
            state: 'coming-soon',
            short_label: 'CS',
            name: 'Coming Soon',
            type: 'link',
            icon: 'feather icon-upload-cloud',
            target: true
          },
          {
            state: 'offline-ui',
            name: 'Offline UI',
            target: true
          }
        ]
      },
      {
        state: 'user',
        short_label: 'U',
        name: 'User Profile',
        type: 'sub',
        icon: 'icon-user',
        children: [
          {
            state: 'profile',
            name: 'User Profile'
          }, {
            state: 'card',
            name: 'User Card'
          }
        ]
      },
      {
        state: 'email',
        short_label: 'E',
        name: 'Email',
        type: 'sub',
        icon: 'feather icon-email',
        children: [
          {
            state: 'email-compose',
            name: 'Compose Email'
          }, {
            state: 'email-inbox',
            name: 'Inbox'
          }, {
            state: 'email-read',
            name: 'Read Mail'
          }, {
            state: 'email-template',
            name: 'Email Template',
            type: 'sub',
            children: [
              {
                state: 'email-welcome',
                name: 'Welcome Email',
                target: true
              },
              {
                state: 'email-reset-password',
                name: 'Reset Password',
                target: true
              },
              {
                state: 'email-newsletter',
                name: 'Newsletter Email',
                target: true
              },
              {
                state: 'app-launch',
                name: 'App Launch',
                target: true
              },
              {
                state: 'activation-code',
                name: 'Activation Code',
                target: true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    label: 'App',
    main: [
      {
        state: 'crm-contact',
        name: 'CRM Contact',
        type: 'link',
        icon: 'feather icon-user'
      },
      {
        state: 'task',
        short_label: 'T',
        name: 'Task',
        type: 'sub',
        icon: 'icon-check-box',
        children: [
          {
            state: 'list',
            name: 'Task List'
          }, {
            state: 'board',
            name: 'Task Board'
          }, {
            state: 'details',
            name: 'Task Details'
          }, {
            state: 'issue',
            name: 'Issue List'
          }
        ]
      }
    ]
  },
  {
    label: 'Extension',
    main: [
      {
        state: 'editor',
        name: 'Editor',
        type: 'sub',
        icon: 'feather icon-pencil',
        children: [
          {
            state: 'quill-edit',
            name: 'Quill'
          }
        ]
      },
      {
        state: 'invoice',
        short_label: 'I',
        name: 'Invoice',
        type: 'sub',
        icon: 'icon-layout-media-right',
        children: [
          {
            state: 'basic',
            name: 'Invoice'
          }, {
            state: 'summary',
            name: 'Invoice Summary'
          }, {
            state: 'list',
            name: 'Invoice List'
          }
        ]
      },
      {
        state: 'file-upload-ui',
        short_label: 'F',
        name: 'File Upload',
        type: 'link',
        icon: 'icon-cloud-up'
      },
      {
        state: 'calendar',
        short_label: 'EC',
        name: 'Event Calendar',
        type: 'link',
        icon: 'feather icon-calendar'
      }
    ]
  },
  {
    label: 'Other',
    main: [
      {
        state: '',
        short_label: 'M',
        name: 'Menu Levels',
        type: 'sub',
        icon: 'icon-direction-alt',
        children: [
          {
            state: '',
            name: 'Menu Level 2.1',
            target: true
          }, {
            state: '',
            name: 'Menu Level 2.2',
            type: 'sub',
            children: [
              {
                state: '',
                name: 'Menu Level 2.2.1',
                target: true
              },
              {
                state: '',
                name: 'Menu Level 2.2.2',
                target: true
              }
            ]
          }, {
            state: '',
            name: 'Menu Level 2.3',
            target: true
          }, {
            state: '',
            name: 'Menu Level 2.4',
            type: 'sub',
            children: [
              {
                state: '',
                name: 'Menu Level 2.4.1',
                target: true
              },
              {
                state: '',
                name: 'Menu Level 2.4.2',
                target: true
              }
            ]
          }
        ]
      },
      {
        state: 'simple-page',
        short_label: 'S',
        name: 'Sample Page',
        type: 'link',
        icon: 'icon-layout-sidebar-left'
      }
    ]
  },
  {
    label: 'Support',
    main: [
      {
        state: 'documentation',
        short_label: 'D',
        name: 'Documentation',
        external: 'http://docs.phoenixcoded.net/mega-able/angular/',
        type: 'external',
        icon: 'icon-file',
        target: true
      },
      {
        state: 'submit-issue',
        short_label: 'S',
        external: 'https://phoenixcoded.ticksy.com/',
        name: 'Submit Issue',
        type: 'external',
        icon: 'icon-layout-list-post',
        target: true
      }
    ]
  }

];

@Injectable()
export class MenuItems {
  public permission
  public menuData = [];
  public menuFinalData = [];
  public MENUITEMS :any;
  constructor(private commonService : CommonService){
    let perm = this.commonService.getUserDataFromLocalStorage().permission;
    this.permission = perm ? JSON.parse(perm) : '';
    // console.log(this.permission)
    this.menuData = JSON.parse(localStorage.getItem(this.commonService.prefix+ 'MenuData'));
    // console.log(this.menuData);
    this.menuFinalData =  this.cat(this.menuData,null);
    // console.log(this.menuFinalData)
    let arr = [];
    this.MENUITEMS = [
      {
        label: '',
        main : this.menuFinalData
      }
    ];
    
    // console.log(this.menuFinalData);
    
  
  }
  getAll(): Menu[] {
    // console.log(this.MENUITEMS);
    return this.MENUITEMS;
  }
  
  cat(data,parent_id){
    let arrayData = []
    data.forEach(ele => {
      // console.log(parent_id)
        if(ele.Parent_id == parent_id){
          // console.log(this.permission)
          let obj:any;
          if(parent_id == null){
            obj = {
              type: 'sub',
              ID : ele.ID,
              short_label : 'K',
              name : ele.Menu_Group,
              icon : ele.Icon,
              state : '', 
              children : this.cat(data,ele.ID1)
            }
          }
          else{
            obj = {
              ID : ele.ID,
              name : ele.Menu_Group,
              state : ele.PATH
            }
          }
          if(this.permission){
            if(parent_id){
              // console.log(parent_id.split('-')[1] + '----'+ ele.ID)
              if(this.permission[parent_id.split('-')[1]] && this.permission[parent_id.split('-')[1]][ele.ID] && this.permission[parent_id.split('-')[1]][ele.ID].view){
                arrayData.push(obj);  
              }
            }
            else{
              arrayData.push(obj);
            }
          }
          else{
            arrayData.push(obj);
          }
          
          
        }
        
    });
    return arrayData;
  }
}
