import { Component, OnInit,Input,Output,EventEmitter, ViewChild } from '@angular/core';
import { CommonService } from '../../common-service.service';

@Component({
  selector: 'app-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrls: ['./grid-view.component.scss']
})
export class GridViewComponent implements OnInit{

  @Input() gridTitle: string;
  @Input() data: Array<any>;
  @Input() headerInfo: Array<any>;
  @Input() actionButtons: Array<any>;
  @Input() itemsPerPage: number;
  @Input() totalItems: number;
  @Input() enableAddBtn: boolean = true;
  @Input() enableMergeBtn: boolean = false;
  @Input() hidefilter: boolean = false;
  @Input() showLoader: boolean = false;
  @Input('defaultFilterValue') filterObj:any = {};


  @Output() updateData = new EventEmitter<any>();
  @Output() triggerAction = new EventEmitter<any>();
  @Output() openRightSidebar = new EventEmitter<any>();
  @Output() readyForMerge = new EventEmitter<any>();
  
  public sr_no=0;
  //this is for filter 
  //public filterObj:any = {};
  sortFilter:any = {};
  //this is for timeout function break;
  time:any;
  showFilter: boolean = false;  
  itemsRecords = this.commonService.ItemsPerPage;

  constructor(private commonService: CommonService) {
    this.itemsPerPage = this.itemsPerPage ? this.itemsPerPage : this.commonService.DefaultItemsPerPage;
    // console.log(this.filterObj);
  }
  
  currentPage: any = 1;

  sortStatus: boolean = false;
  sorting(header){
    
    this.headerInfo.forEach(element => {
      if(header.name != element.name){
        element.sortStatus = undefined;
      }
    });
    if(header.sortStatus == undefined){
      header.sortStatus = 'asc'
    }
    else if(header.sortStatus == 'asc'){
      header.sortStatus = 'desc'
    }
    else{
      header.sortStatus = undefined;
    }
    this.sortFilter = {[header.name] : header.sortStatus} 
    this.sendFilterObjectForUpdateData();
    // console.log(header)
  }
  // getSortStatus(header){
  //   return header.status
  //   // return false;     
  // }

  
  ngOnInit(){
    }

  changePage(page){
    this.currentPage = page;
    this.sr_no = (this.currentPage-1) * this.itemsPerPage
    this.sendFilterObjectForUpdateData();
    window.scroll(0,0);
    // this.updateData.emit(page)
  }

  //when user click action button(e.g ADD,EDIT,etc.)
  clickOnAction(actionName,obj){  
    this.triggerAction.emit({
      'actionName' : actionName,
      'object' : obj
    })
  }

  //when filter text
  filterText(){
    console.log(this.filterObj);
    console.log(this.showFilter);
    console.log(this.time);
        
    if(this.showFilter || true){
      if(this.time){
        clearTimeout(this.time);
      }
      this.time = setTimeout(()=>{
        this.currentPage = 1;
        this.sendFilterObjectForUpdateData();
      }, 600);
    }
  }

  //when update data
  sendFilterObjectForUpdateData(){
   let makeFilterObj = {}
    Object.keys(this.filterObj).forEach((key) => {
      makeFilterObj[key] = (this.filterObj[key] && typeof this.filterObj[key] == "string") ? this.filterObj[key].replace(/'/g, "\\\'").replace(/"/g, '\\\"') : this.filterObj[key];
    });
    console.log(makeFilterObj);
    let filter = {
      filter : makeFilterObj,
      page : this.currentPage,
      sort : this.sortFilter,
      itemsPerPage : this.itemsPerPage
    }
    console.log(filter); 
    this.updateData.emit(filter);
  }

  funOpenRightSidebar(){
    this.openRightSidebar.emit();
  }

  clickOnMergeBtn(){
    this.readyForMerge.emit();
  }
}


// README

//Header Array Information
/** 
 * :::::------| This is for only one column |-------::::::::

 * [title] :- title of column(type :- string , e.g : 'Company Name')
 * [name] :- name of column in data source (type :- string , e.g : 'COMPANY_NAME')
 * [width] :- width of td in percentage (type :- string , e.g : '20%' )
 * [isSortable] :- sort by column false or true (type :- boolean , e.g : true )
 * [highlight] :- Highlight column value  (type :- boolean , e.g : true )
 * [type] :- define which type of column (type :- string , e.g : 'email'),default is simple display text
 * [isFilter] : filter by single column (type :- object)
                e.g :- 
                {
                  type : 'text',
                  placeholder : 'Filter By Company'
                }
  * [display_value] :- display custom value instead of default value

    note : you stored '1' or '0' for Active Or Inactive status ,but you want display 'active' or 'Inactive' instead of  '0' or '1'
    
    e.g :-  {
              0 : Inactive,
              1 : Active
            }
 * */  
