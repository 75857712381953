
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from './common-service.service';

@Component({
  selector: 'app-button-renderer',
  templateUrl: './button-renderer.component.html',
})




// template: `<i *ngIf="params.data.view_btn_show == true" (click)="onClick($event,1)" class="{{label1}} button-icon" placement="left" ngbTooltip="View"></i><i *ngIf="params.data.edit_btn_show == true" (click)="onClick($event,2)" class="{{label2}} button-icon" placement="left" ngbTooltip="Edit"></i><i *ngIf="params.data.shortclose_btn_show == true" (click)="onClick($event,'shortclose')" class="{{label5}} button-icon" placement="left" ngbTooltip="Short Close"></i><i *ngIf="params.data.take_input_btn_show == true" (click)="onClick($event,'take_input')" class="{{label5}} button-icon" placement="left" ngbTooltip="Take Input"></i><i *ngIf="params.data.approve_btn_show == true" (click)="onClick($event,3)" class="{{label3_approve}} button-icon" placement="left" ngbTooltip="Approve"></i><i *ngIf="params.data.delete_btn_show == true" (click)="onClick($event,3)" class="{{label3}} button-icon" placement="left" ngbTooltip="Delete"></i><i *ngIf="params.data.is_cancel == true" (click)="onClick($event,6)" class="{{label6}} button-icon" placement="left" ngbTooltip="Cancel"></i><i *ngIf="params.data.pdf_btn_show == true" (click)="onClick($event,4)" class="{{label4}} button-icon" placement="left" ngbTooltip="PDF"></i><div *ngIf="params.data.radio_btn_show == true" class="border-checkbox-group border-checkbox-group-primary"><input class="border-checkbox" value="{{radio1.ID}}" (click)="onClick($event,5)" type="radio" [(ngModel)]="radioSelected" [ngModelOptions]="{standalone: true}" id="radio1" name="selGrn"><label class="border-checkbox-label" for="radio1"></label></div><input  *ngIf="params.data.checkbox_show == true" class="border-checkbox" (click)="onClick($event,7)" type="checkbox" [(ngModel)]="checkboxSelected" [ngModelOptions]="{standalone: true}" id="checkbox" name="selcheckbox">`
// })

export class ButtonRendererComponent implements ICellRendererAngularComp {

  constructor(
    public CommonService: CommonService
  ) {

  }
  public nodeApi = this.CommonService.nodeApi;
  public prefix = "AVORGANICS" + this.nodeApi + "_";

  params;
  permission;
  label1: string;
  label2: string;
  label3: string;
  label4: string;
  label5: string;
  label6: string;
  radio1: string;
  checkbox: string;
  label3_approve: String;
  label3_delete: String;
  agInit(params): void {
    this.params = params;
    console.log(this.params);
    this.label1 = this.params.label1 || null;
    this.label2 = this.params.label2 || null;
    this.label3 = this.params.label3 || null;
    this.label3_approve = this.params.label3_approve || null;
    this.label3_delete = this.params.label3_delete || null;
    this.label4 = this.params.label4 || null;
    this.label5 = this.params.label5 || null;
    this.label6 = this.params.label6 || null;
    this.radio1 = this.params.data || null;
    this.checkbox = this.params.checkbox || null;
    let perm;
    if (localStorage.getItem(this.prefix + "userData") != "undefined") {
      perm = localStorage.getItem(this.prefix + "userData");
    }

    if (JSON.parse(perm).permission) {
      // this.permission = JSON.parse(perm).permission[this.params.parent_module_id][this.params.module_id];
    }
    else {
      this.permission = { "add": true, "view": true, "edit": true, "delete": true }
    }

  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event, i) {


    console.log(i);

    var st = false;
    var st2 = false;

    if (this.params.onClick instanceof Function) {
      var my_Data: any = {};

      if (typeof this.params.node.data != "undefined") { //  if child
        my_Data = this.params.node.data;
      }
      else { // if parent
        my_Data = this.params.node.allLeafChildren[0].data;
      }


      if (typeof this.params.node.data != "undefined" && this.params.node.data.Input_Transaction_Code) { //  if child

        st2 = true;
        st = false;
      }
      else { // if parent

        if (i == "take_input") {

          st2 = true;
          st = false;
        }
        else {

          st2 = false;
          st = true;
        }



      }

      const params = {
        event: $event,
        rowData: my_Data,
        show_Tab: st,
        show_Tab2: st2
      }
      this.params.onClick(params, i);

    }
  }
}
