

import { Injectable } from "@angular/core";
// import { Http, Response } from '@angular/http';
import { HttpClient } from "@angular/common/http";
// import { HttpHeaders } from '@angular/common/http';
// import {Observable} from 'rxjs/Observable';
import { Router, ActivatedRoute } from "@angular/router";
import "rxjs/Rx";
declare const $: any;
@Injectable({
  providedIn: "root"
})
export class CommonService {
  private serviceData: any;

  public nodeApi = 'https://erp.avorganics.in/api';//for node server api
  private serverImagePath = "https://erp.avorganics.in/api/images";
  private serverVideoPath = "https://erp.avorganics.in/api/videos";
  private serverDocumentPath = "https://erp.avorganics.in/api/document";

  public prefix = "AVORGANICS" + this.nodeApi + "_";
  public passwordRegex = "^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
  public numberRegex = "^[0-9]*$";
  public passwordRegexErrorMessage =
    "Password must have 8 characters,one Special characters Upper and Lowercase letters";
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  private extractData(res) {
    this.serviceData = res;
    if (this.serviceData.status == false) {
    }
    return this.serviceData || {};
  }

  generateCode(prefix, companyUnit, fnYear, id) {
    return (
      prefix + "/" + companyUnit + "/" + fnYear + "/" + ("000" + id).slice(-4)
    );
  }
  sendRequestToNode(params) {
    // this.showLoader();
    params.url = this.nodeApi; // override node api
    var resArr;
    if (params.method && params.method == "post") {
      resArr = this.http
        .post(params.url + "/" + params.path, params.postData) // define a variable server_url to assign the requested url
        .map(this.extractData);
    } else if (params.method && params.method == "delete") {
      resArr = this.http
        .delete(params.url + "/" + params.path) // define a variable server_url to assign the requested url
        .map(this.extractData);
    } else if (params.method && params.method == "put") {
      resArr = this.http
        .put(params.url + "/" + params.path, params.postData) // define a variable server_url to assign the requested url
        .map(this.extractData);
    } else {
      resArr = this.http
        .get(params.url + "/" + params.path, { params: params.queryParam }) // define a variable server_url to assign the requested url
        .map(this.extractData);
    }

    return resArr;
  }

  getNodeApi() {
    return this.nodeApi;
  }

  getImagePath() {
    return this.serverImagePath;
  }
  getDocumentPath() {
    return this.serverDocumentPath;
  }
  getVidepPath() {
    return this.serverVideoPath;
  }

  hideLoader() {
    $("#loader").addClass("myloader-hide");
  }
  showLoader() {
    $("#loader").removeClass("myloader-hide");
  }
  getUserDataFromLocalStorage() {
    if (localStorage.getItem(this.prefix + "userData") != "undefined") {
      return JSON.parse(localStorage.getItem(this.prefix + "userData"));
    }
    return null;
  }
  DAILY_PRODUCTION_CODE = "PROD/";
  PRODUCTION_INPUT = "PRDIN/";
  STORE_REQ_PREFIX = "SR/";
  STORE_ISSUE_PREFIX = "IS/";
  DEHLIVERYNOTE_PREFIX = "DN/";
  SALESINVOICE_PREFIX = "SI/";


  PRODUCTION_ORDER = "BT/";
  SALES_RECEIVABLES_PREFIX = "RV/" ;
  GRN_PREFIX = "GRN/";
  GIN_PREFIX = "GIN/";
  STOCK_ADJUSTMENTS_PREFIX = "ADJ/";
  PURCHASE_INVOICE_PREFIX = "PI/";
  STORE_RETURN_PREFIX = "RTN/";
  SALES_FREIGHT_PREFIX = "SF/"

  formatDate(date, format = "") {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (format == "m-d-y") {
      return [month, year, day].join("-");
    } else if (format == "d-m-y") {
      return [day, month, year].join("-");
    } else if (format == "d-m") {
      return [day, month].join("-");
    } else if (format == "d/m/y") {
      return [day, month, year].join("/");
    } else if (format == "y-m-d") {
      return [year, month, day].join("-");
    } else {
      return [year, month, day].join("-");
    }
  }
  DIGITSFIXED = 3;
  public ItemsPerPage = [5, 10, 15, 20];
  public DefaultItemsPerPage = 10;

  deleteTitle = "Are you sure?";
  deleteText = "You won't be able to revert this!";
  confirmButtonText = "Yes";
  sussDeleteDialogueTitle = "Record Deleted!";
  sussCancelledDialogueTitle = "Record Cancelled!";
  sussInsertDialogueTitle = "Record Saved!";
  sussUpdateDialogueTitle = "Record Updated!";
}


